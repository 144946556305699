import { render, staticRenderFns } from "./AddDeliveryMethodModal.vue?vue&type=template&id=5facc0d7&scoped=true&"
import script from "./AddDeliveryMethodModal.vue?vue&type=script&lang=js&"
export * from "./AddDeliveryMethodModal.vue?vue&type=script&lang=js&"
import style0 from "./AddDeliveryMethodModal.vue?vue&type=style&index=0&id=5facc0d7&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5facc0d7",
  null
  
)

export default component.exports