<template>
    <div>
        <CreatePartners
            v-if="checkPartnerEdit"
            :partnerTitle="partnerTitle"
            :buttonPanelDetails="buttonPanelDetails"
            :counterDetails="counterDetails"
            :filterObject="filterObject"
            :deliveryMethodHeader="deliveryMethodHeader"
            :deliveryMethodRow="deliveryMethodRow"
            :rowDeleteDisabledButton="rowDeleteDisabledButton"
            :editPartnerData="editPartnerData"
            :sortType="sortType"
            :sortOrder="sortOrder"
            :sortColIndex="sortColIndex"
            :showLoader="showLoader"
            @onClickPanelButton="onClickPanelButton"
            @rowDeleteActionButton="rowDeleteActionButton"
            @handleMultiSelection="handleMultiSelection"
            @handleRowSelectEvent="handleRowSelectEvent"
            @savePartner="savePartner"
            @updatePartner="updatePartner"
            @handleSortEvent="handleSortEvent"
        ></CreatePartners>
        <addDeliveryMethodModal
            v-if="addDeliveryMethodModal"
            :toggleModalComponent="addDeliveryMethodModal"
            :getDeliveryType="dropDownDeliveryMethodList"
            @closeModal="closeaddDeliveryMethodModal"
            @saveModal="saveaddDeliveryMethodModal"
        ></addDeliveryMethodModal>
        <notification-component
            v-if="showNotification"
            :notificationMessage="notificationMessage"
            :notificationType="notificationType"
            :keyType="notificationKey"
            :dismissDelay="5000"
            @close="showNotification = false"
        ></notification-component>
    </div>
</template>

<script>
import get from "lodash/get";
import CreatePartners from "../components/CreatePartners.vue";
import { mapGetters, mapState } from "vuex";
import store from "@/store";
import AddDeliveryMethodModal from "../container/AddDeliveryMethodModal.vue";
import { PARTNER_ACTIONS } from "@/store/modules/partner/contants";
import NotificationComponent from "../../../ui_component/notificationComponent.vue";

export default {
    beforeCreate() {
        this.$store = store;
    },
    components: {
        CreatePartners,
        AddDeliveryMethodModal,
        NotificationComponent,
    },
    data() {
        return {
            showLoader: true,
            partnerTitle: "Create Partner",
            counterDetails: {
                displayCounterDetails: true,
                count: 0,
                countText: "Selected",
                showVerticalLine: true,
            },
            buttonPanelDetails: [
                {
                    id: "AddButtonId",
                    buttonName: "Add Delivery Method",
                    iconName: "plus",
                    iconColor: "defaultColor",
                    isDisabled: false,
                    showVerticalLine: false,
                    isText: false,
                },
                {
                    id: "DeleteButtonId",
                    buttonName: "Delete",
                    iconName: "trash",
                    iconColor: "defaultColor",
                    isDisabled: true,
                    showVerticalLine: false,
                    isText: false,
                },
            ],
            filterObject: [],
            addDeliveryMethodModal: false,
            deliveryMethodHeader: [
                {
                    fieldName: "methodtypename",
                    text: "Method",
                    width: "50%",
                    sortable: true,
                    sorted: 1,
                },
                {
                    fieldName: "details",
                    text: "Details",
                    width: "40%",
                },
                {
                    fieldName: "actions",
                    text: "Actions",
                    width: "10%",
                    align: "right",
                },
            ],
            deliveryMethodRow: [],
            selectMultiRowData: [],
            rowDeleteDisabledButton: false,
            deliveryMethodRequest: [],

            showNotification: false,
            notificationMessage: "",
            notificationType: "",
            editPartnerData: {},
            vendorid: "",
            addDeliveryMethods: [],
            addDeliveryMethodsRequest: [],
            removeMethodId: [],
            //sorting
            sortType: false,
            sortOrder: 1,
            sortColIndex: 0,
        };
    },
    created() {},
    async mounted() {
        this.showLoader = true;
        this.vendorid = await this.$route.query.vendorid;
        if (this.vendorid != undefined) {
            var data = {
                partnerId: this.vendorid,
            };
            await this.$store.dispatch(
                PARTNER_ACTIONS.FETCH_EDIT_PARTNER_LIST,
                {
                    data,
                }
            );
            const response = this.partnerEdit;
            this.editPartnerData = response;
            this.partnerTitle = "Edit Partner: " + response.vendorname;
            this.deliveryMethodRow = response.deliverymethods;
            this.commonsorting();
        }
        await this.cachedDispatch(
            PARTNER_ACTIONS.FETCH_DROPDOWN_DELIVERYTYPE_LIST
        );
        this.dropDownDeliveryMethodList;
        this.showLoader = false;
    },
    computed: {
        ...mapGetters(["cachedDispatch"]),
        ...mapState({
            dropDownDeliveryMethodList(state) {
                return get(
                    state,
                    "partner.dropDownDeliveryTypes.dropDownDeliveryTypes",
                    []
                );
            },
            topDeleteIcon() {
                return this.buttonPanelDetails.filter(
                    (x) => x.id === "DeleteButtonId"
                );
            },
            partnerSave(state) {
                return get(state, "partner.savePartners.savePartners", []);
            },
            partnerSaveError(state) {
                return get(state, "partner.savePartners.__error__", null);
            },
            partnerEdit(state) {
                return get(state, "partner.editPartners.editPartners", []);
            },
            partnerUpdate(state) {
                return get(state, "partner.updatePartners.updatePartners", []);
            },
            partnerUpdateError(state) {
                return get(state, "partner.updatePartners.__error__", null);
            },
            createPartnerLoader(state) {
                const loader = this.vendorid
                    ? get(state, "partner.editPartners.__loading__", false) &&
                      get(state, "partner.editPartners.__loading__", false)
                    : get(
                          state,
                          "partner.dropDownDeliveryTypes.__loading__",
                          false
                      );
                return loader;
            },
        }),
        checkPartnerEdit() {
            if (this.$route.query.vendorid) {
                return Object.keys(this.editPartnerData).length;
            } else {
                return true;
            }
        },
    },
    methods: {
        //top header action button
        onClickPanelButton(val) {
            if (val === "Add Delivery Method") {
                this.addDeliveryMethod();
            } else if (val === "Delete") {
                this.deleteDeliveryMethod();
            }
        },

        //on add delivery method modal
        addDeliveryMethod() {
            this.addDeliveryMethodModal = true;
        },

        // close delivery method modal
        closeaddDeliveryMethodModal() {
            this.addDeliveryMethodModal = false;
        },

        //  SAVE 1 DELIVERY METHOD
        saveaddDeliveryMethodModal(data) {

            //this condition true when we create partner 
            if (this.vendorid == undefined) {
                if (this.deliveryMethodRow.length > 0) {
                    if (data.methodtypename == "FTP") {
                        this.deliveryMethodRow.forEach((item, index) => {
                            if (item.details === data.details) {
                                this.deliveryMethodRow.splice(index, 1);
                            }
                        });
                    } else if (data.methodtypename == "Email") {
                        this.deliveryMethodRow.forEach((item, index) => {
                            if (item.to === data.to) {
                                this.deliveryMethodRow.splice(index, 1);
                            }
                        });
                    }
                }
            } 
            // this condition is true when we update partner
            else if (this.vendorid.length > 0) {

                // when delivery type is FTP
                if (data.methodtypename == "FTP") {
                    this.deliveryMethodRow.forEach((item, index) => {
                        if (item.details === data.details) {
                            this.deliveryMethodRow.splice(index, 1);
                            if (item.configid != undefined) {
                                this.removeMethodId.push(item.configid);
                            }
                        }
                    });
                    this.addDeliveryMethods.forEach((item, index) => {
                    if (item.details === data.details) {
                        this.addDeliveryMethods.splice(index, 1);
                    }
                });
                } 
                
                // when delivery type is EMAIL
                else if (data.methodtypename == "Email") {
                    this.deliveryMethodRow.forEach((item, index) => {
                        if (item.details === data.to) {
                            this.deliveryMethodRow.splice(index, 1);
                            if (item.configid != undefined) {
                                this.removeMethodId.push(item.configid);
                            }
                        }
                    });
                    this.addDeliveryMethods.forEach((item, index) => {
                    if (item.details === data.to) {
                        this.addDeliveryMethods.splice(index, 1);
                    }
                });
                }
                this.addDeliveryMethods.push(data);
            }
            this.deliveryMethodRow.push(data);
            this.addDeliveryMethodModal = false;
            this.commonsorting();
        },

        // DELETE ROW DELIVERY METHOD
        rowDeleteActionButton(value) {
            this.deliveryMethodRow.forEach((item, index) => {
                if (item == value) {
                    this.deliveryMethodRow.splice(index, 1);
                }
            });
            if (this.vendorid !== undefined) {
                if (value.configid !== undefined) {
                    this.removeMethodId.push(value.configid);
                }
                this.addDeliveryMethods.forEach((item, index) => {
                    if (item == value) {
                        this.addDeliveryMethods.splice(index, 1);
                    }
                });
            }
        },

        // event for multiselection
        handleMultiSelection(event) {
            for (let i = 0; i < this.deliveryMethodRow.length; i++) {
                this.$set(
                    this.deliveryMethodRow[i],
                    "checked",
                    event.target.checked
                );
            }
        },

        // event for single row selection
        handleRowSelectEvent(index, event) {
            this.selectMultiRowData = this.deliveryMethodRow.filter(
                (el) => el.checked
            );
            this.counterDetails.count = this.selectMultiRowData.length;
            if (this.selectMultiRowData.length === 1) {
                this.disableTopIcons("singleSelect");
                this.rowDeleteDisabledButton = true;
            }
            if (this.selectMultiRowData.length > 1) {
                this.disableTopIcons("singleSelect");
                this.rowDeleteDisabledButton = true;
            }
            if (!this.selectMultiRowData.length) {
                this.disableTopIcons("noSelect");
                this.rowDeleteDisabledButton = false;
            }
            this.$set(this.deliveryMethodRow[index], "checked", event);
        },

        // event for disabling top icons
        disableTopIcons(params) {
            this.topDeleteIcon[0].isDisabled =
                params === "singleSelect" ? false : true;
        },

        // delete multiple delivery method
        deleteDeliveryMethod() {
            if (this.vendorid !== undefined) {
                this.selectMultiRowData.forEach((element) => {
                    this.deliveryMethodRow.forEach((item) => {
                        if (item == element && item.configid !== undefined) {
                            this.removeMethodId.push(item.configid);
                        }
                    });
                    this.addDeliveryMethods.forEach((item, index) => {
                        if (item == element) {
                            this.addDeliveryMethods.splice(index, 1);
                        }
                    });
                });
            }
            this.selectMultiRowData.forEach((element) => {
                this.deliveryMethodRow.forEach((item, index) => {
                    if (item == element) {
                        this.deliveryMethodRow.splice(index, 1);
                    }
                });
            });
            this.selectMultiRowData = [];
            this.rowDeleteDisabledButton = false;
            this.counterDetails.count = 0;
            this.disableTopIcons("noSelect");
        },

        // save partner
        async savePartner(val) {
            let data = {
                vendorName: "",
                deliveryMethods: [],
            };
            let methodTypeId = this.deliveryMethodRow.map(
                (item) => item.methodTypeId
            );
            let server = this.deliveryMethodRow.map((item) => item.server);
            let to = this.deliveryMethodRow.map((item) => item.to);
            let bcc = this.deliveryMethodRow.map((item) => item.bcc);
            let cc = this.deliveryMethodRow.map((item) => item.cc);
            let userName = this.deliveryMethodRow.map((item) => item.userName);
            let password = this.deliveryMethodRow.map((item) => item.password);

            for (let i = 0; i < this.deliveryMethodRow.length; i++) {
                this.deliveryMethodRequest.push({
                    methodTypeId: methodTypeId[i],
                    server: server[i],
                    to: to[i],
                    bcc: bcc[i],
                    cc: cc[i],
                    userName: userName[i],
                    password: password[i],
                });
            }

            data.vendorName = val;
            data.deliveryMethods = this.deliveryMethodRequest;
            await this.$store.dispatch(
                PARTNER_ACTIONS.FETCH_SAVE_PARTNER_LIST,
                {
                    data,
                }
            );
            let response = this.partnerSave;
            const obj = {
                response,
                ntfnMsg: "The partner has been created.",
                ntfnType: "success",
                ntfnKey: "success-default",
                ntfnfailureMsg: this.partnerSaveError,
            };
            this.notificationResponse(obj);

            if (!this.partnerSaveError) {
                setTimeout(() => {
                    this.$router.push({
                        name: "Data Dissemination Partner",
                    });
                }, 2000);
            }else{
                this.deliveryMethodRequest=[];
            }
        },

        // update partner
        async updatePartner() {
            let data = {
                vendorId: this.vendorid,
                vendorName: "",
                addDeliveryMethods: [],
                removeMethodId: [],
            };
            let methodTypeId = this.addDeliveryMethods.map(
                (item) => item.methodTypeId
            );
            let server = this.addDeliveryMethods.map((item) => item.server);
            let to = this.addDeliveryMethods.map((item) => item.to);
            let bcc = this.addDeliveryMethods.map((item) => item.bcc);
            let cc = this.addDeliveryMethods.map((item) => item.cc);
            let userName = this.addDeliveryMethods.map((item) => item.userName);
            let password = this.addDeliveryMethods.map((item) => item.password);

            for (let i = 0; i < this.addDeliveryMethods.length; i++) {
                this.addDeliveryMethodsRequest.push({
                    methodTypeId: methodTypeId[i],
                    server: server[i],
                    to: to[i],
                    bcc: bcc[i],
                    cc: cc[i],
                    userName: userName[i],
                    password: password[i],
                });
            }

            data.addDeliveryMethods = this.addDeliveryMethodsRequest;
            data.removeMethodId = this.removeMethodId;
            await this.$store.dispatch(
                PARTNER_ACTIONS.FETCH_UPDATE_PARTNER_LIST,
                {
                    data,
                }
            );
            let response = this.partnerUpdate;
            const obj = {
                response,
                ntfnMsg: "The partner has been updated.",
                ntfnType: "success",
                ntfnKey: "success-default",
                ntfnfailureMsg: this.partnerUpdateError,
            };
            this.notificationResponse(obj);

            if (!this.partnerSaveError && (response.statuscode == 201 || response.statuscode == 200)) {
                setTimeout(() => {
                    this.$router.push({
                        name: "Data Dissemination Partner",
                    });
                }, 2000);
            }else{
                this.addDeliveryMethodsRequest=[];
            }
        },

        // notification event
        notificationResponse({
            response,
            ntfnMsg,
            ntfnType,
            ntfnKey,
            ntfnfailureMsg,
        }) {
            if (response.statuscode == 201 || response.statuscode == 200) {
                this.notificationMessage = ntfnMsg;
                this.notificationType = ntfnType;
                this.showNotification = true;
                this.notificationKey = ntfnKey;
                this.showLoader = false;
            }else if(ntfnfailureMsg =="No changes were made."){
                this.showLoader = false;
                this.notificationKey = "informational-example";
                this.notificationMessage = ntfnfailureMsg;
                this.notificationType = "informational";
                this.showNotification = true;
            } 
            else {
                this.showLoader = false;
                this.notificationKey = "error-default";
                this.notificationMessage = ntfnfailureMsg;
                this.notificationType = "error";
                this.showNotification = true;
            }
        },

        //sorting
        executeSortingLogicForMdsTable(colIndex) {
            this.sortOrder = this.sortOrder === 0 ? 1 : -this.sortOrder;

            if (this.sortColIndex !== null && this.sortColIndex !== colIndex) {
                this.$set(
                    this.deliveryMethodHeader[this.sortColIndex],
                    "sorted",
                    0
                );
                this.sortOrder = -1;
            }

            this.sortColIndex = colIndex;
            this.$set(
                this.deliveryMethodHeader[colIndex],
                "sorted",
                this.sortOrder
            );
        },

        handleSortEvent(colIndex, colName) {
            this.executeSortingLogicForMdsTable(colIndex);
            this.orderByColumn = colName;
            this.commonsorting();
        },

        commonsorting() {
            if (this.sortOrder == 1) {
                this.deliveryMethodRow = this.deliveryMethodRow.sort((a, b) =>
                    a.methodtypename <= b.methodtypename ? 1 : -1
                );
            } else {
                this.deliveryMethodRow = this.deliveryMethodRow.sort((a, b) =>
                    a.methodtypename <= b.methodtypename ? -1 : 1
                );
            }
        },
    },
};
</script>

