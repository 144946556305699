<template>
    <div>
        <mds-modal
            :title="ruleHeaderName"
            :width="'600px'"
            v-model="toggleModalComponent"
            action-required
        >
            <div>
                <mds-form>
                    <mds-select
                        label="Delivery Type"
                        v-model="deliveryType"
                        @change="onScope($event)"
                    >
                        <option value="">Select a delivery type</option>
                        <option
                            v-for="item in getDeliveryType"
                            :key="item.id"
                            :value="item.name"
                        >
                            {{ item.name }}
                        </option>
                    </mds-select>

                    <!-- FTP -->
                    <span v-if="deliveryType == 'FTP'">
                        <mds-select
                            label="FTP Server"
                            v-model="singleFTPServer"
                            :disabled="isFTPServerDisable"
                            @change="onChangeServer($event)"
                        >
                            <option value="">Select FTP Server</option>
                            <option
                                v-for="(item, index) in FTPServerList"
                                :key="index"
                                :value="item"
                            >
                                {{ item }}
                            </option>
                        </mds-select>
                        <div class="addftpServerButton">
                            <button-component
                                id="ftpServerButton"
                                style="float: right"
                                :onClick="FTPServerButton"
                                buttonName="Add FTP Server"
                                buttonVariation="flat"
                                iconName="plus"
                            >
                            </button-component>
                        </div>
                        <div
                            v-if="onClickAddFTPServer || onChangeFTPServer"
                            class="addftpServer"
                        >
                            <mds-input
                                label="FTP Folder Location"
                                placeholder="Input Location"
                                maxlength="250"
                                :disabled="onChangeFTPServer"
                                v-model.trim="ftpFolderLocation"
                            ></mds-input>
                            <mds-input
                                label="FTP Server"
                                placeholder="Input Server"
                                maxlength="250"
                                :disabled="onChangeFTPServer"
                                v-model.trim="ftpServer"
                            ></mds-input>
                            <mds-input
                                label="FTP Username"
                                maxlength="250"
                                placeholder="Input Username"
                                :disabled="onChangeFTPServer"
                                v-model.trim="ftpUsername"
                            ></mds-input>
                            <mds-input
                                label="FTP Password"
                                maxlength="250"
                                placeholder="Input Password"
                                type="password"
                                v-model.trim="ftpPassword"
                            ></mds-input>
                            </div>
                    </span>
                    <span v-if="deliveryType == 'Email'">
                        <mds-input
                            label="Email Id"
                            type="email"
                            maxlength="250"
                            @focus="triggerInputField('email')"
                            :error="toEmail.error"
                            :error-text="[toEmail.errorMsg]"
                            v-model.trim="toEmail.data"
                        ></mds-input>
                    </span>
                </mds-form>
            </div>
            <mds-layout-grid style="padding-top: 44px">
                <mds-row>
                    <mds-col :cols="6">
                        <mds-button
                            v-if="onClickAddFTPServer || onChangeFTPServer"
                            @click="FTPServerBackButton"
                            variation="secondary"
                            type="button"
                        >
                            Back
                        </mds-button>
                    </mds-col>
                    <mds-col :cols="6" class="delivery-method-popup-save-button">
                        <mds-button
                            @click="onSaveClick"
                            type="button"
                            variation="primary"
                            :disabled="!addRuleButtonDisable"
                        >
                            Save
                        </mds-button>
                    </mds-col>
                </mds-row>
            </mds-layout-grid>
            
            <template v-slot:mds-modal-actions>
                <mds-button-container right-aligned>
                    <button-component
                                id="ftpCancelServerButton"
                                :onClick="onCancelClick"
                                buttonSize="large"
                                buttonName=""
                                buttonVariation="flat"
                                iconName="remove"
                            >
                            </button-component>
                </mds-button-container>
            </template>
        </mds-modal>

        <loader-component v-if="showLoader"></loader-component>
    </div>
</template>
<script>
import MdsModal from "@mds/modal";
import MdsSelect from "@mds/select";
import MdsForm from "@mds/form";
import MdsInput from "@mds/input";
import { MdsButton, MdsButtonContainer } from "@mds/button";
import { validateMultipleEmail } from "@/helpers/validator.js";
import ButtonComponent from "../../../ui_component/ButtonComponent.vue";
import get from "lodash/get";
import { mapGetters, mapState } from "vuex";
import { PARTNER_ACTIONS } from "@/store/modules/partner/contants";
import LoaderComponent from "../../../ui_component/loaderComponent.vue";
import { MdsLayoutGrid, MdsRow, MdsCol } from "@mds/layout-grid";

export default {
    name: "addDeliveryMethodModal",
    components: {
        MdsModal,
        ButtonComponent,
        MdsButtonContainer,
        MdsForm,
        MdsSelect,
        MdsInput,
        LoaderComponent,
        MdsLayoutGrid,
        MdsRow,
        MdsCol,
        MdsButton,
    },

    data() {
        return {
            toggle: true,
            ruleHeaderName: "Add Delivery Method",
            selectedDeliveryType: "",
            toEmail: {
                data: "",
                error: false,
                errorMsg: "Enter valid email with max length 250 characters",
            },
            bccEmail: "",
            ccEmail: "",
            deliveryType: "",
            onClickAddFTPServer: false,
            ftpFolderLocation: "",
            ftpServer: "",
            ftpUsername: "",
            ftpPassword: "",
            showLoader: false,
            FTPServerList: [],
            onChangeFTPServer: false,
            singleFTPServer: "",
        };
    },

    props: {
        getDeliveryType: Array,
        toggleModalComponent: {
            type: Boolean,
            default: false,
        },
    },

    mounted() {
        this.toggle = true;
    },

    computed: {
        ...mapGetters(["cachedDispatch"]),
        ...mapState({
            dropDownFTPServerList(state) {
                return get(
                    state,
                    "partner.dropDownFTPServers.dropDownFTPServers",
                    []
                );
            },
        }),

        addRuleButtonDisable() {
            if (this.selectedDeliveryType == "Email") {
                return (
                    (this.toEmail.data || this.bccEmail || this.ccEmail) &&
                    this.deliveryType
                );
            } else {
                return (
                    this.ftpServer &&
                    this.ftpUsername &&
                    this.ftpPassword &&
                    this.deliveryType
                );
            }
        },

        isFTPServerDisable() {
            return this.onClickAddFTPServer || this.onChangeFTPServer;
        },
    },

    methods: {
        onCancelClick() {
            this.$emit("closeModal");
        },

        triggerInputField(param) {
            if (param === "email") {
                this.$data.toEmail.error = false;
            }
        },

        onSaveClick() {
            let data = {};
            if (this.selectedDeliveryType == "Email") {
                if (
                    this.$data.toEmail.data &&
                    !validateMultipleEmail(this.$data.toEmail.data)
                ) {
                    this.$data.toEmail.error = true;
                    return;
                } else if (
                    this.$data.toEmail.data &&
                    validateMultipleEmail(this.$data.toEmail.data)
                ) {
                    this.$data.toEmail.error = false;
                }
                data.methodTypeId = 1;
                data.methodtypename = this.deliveryType;
                data.details = this.toEmail.data.replace(/\s/g, "");
                data.to = this.toEmail.data.replace(/\s/g, "");
                data.bcc = this.bccEmail;
                data.cc = this.ccEmail;
            } else if (this.selectedDeliveryType == "FTP") {
                data.methodTypeId = 2;
                data.server = this.ftpServer;
                if (this.ftpFolderLocation === "null") {
                    this.ftpFolderLocation = "";
                }
                data.details =
                    this.ftpFolderLocation +
                    " | " +
                    this.ftpServer +
                    " | " +
                    this.ftpUsername;
                data.methodtypename = this.deliveryType;
                data.userName = this.ftpUsername;
                data.password = this.ftpPassword;
            }
            this.$emit("saveModal", data);
            this.toggle = false;
        },

        async onScope() {
            this.selectedDeliveryType = this.deliveryType;
            this.onClickAddFTPServer = false;
            this.onChangeFTPServer = false;
            if (this.selectedDeliveryType == "FTP") {
                this.showLoader = true;
                await this.$store.dispatch(
                    PARTNER_ACTIONS.FETCH_DROPDOWN_FTPSERVER_LIST
                );
                this.getdropdownFTPServerList();
                this.showLoader = false;
            }
        },

        getdropdownFTPServerList() {
            this.FTPServerList = [];
            this.singleFTPServer = "";
            this.ftpFolderLocation = "";
            this.ftpServer = "";
            this.ftpUsername = "";
            this.ftpPassword = "";
            this.dropDownFTPServerList.forEach((item) => {
                let res =
                    item.ftpfolder +
                    " | " +
                    item.server +
                    " | " +
                    item.username;
                this.FTPServerList.push(res);
            });
        },

        onChangeServer() {
            let temp = this.singleFTPServer.split(" | ");
            this.ftpFolderLocation = temp[0];
            this.ftpServer = temp[1];
            this.ftpUsername = temp[2];
            this.onChangeFTPServer = true;
        },

        FTPServerButton() {
            this.singleFTPServer = "";
            this.ftpFolderLocation = "";
            this.ftpServer = "";
            this.ftpUsername = "";
            this.ftpPassword = "";
            this.onClickAddFTPServer = true;
            this.onChangeFTPServer = false;
        },

        FTPServerBackButton() {
            this.singleFTPServer = "";
            this.onClickAddFTPServer = false;
            this.onChangeFTPServer = false;
        },
    },
};
</script>

<style lang="scss" scoped>
@import "../../../../assets/css/styles.scss";
.sub-heading {
    @include mds-level-5-heading($bold: true);
    color: $mds-text-color-primary;
}

.addftpServerButton {
    margin-bottom: $mds-space-5-x;
}

.delivery-method-popup-save-button {
    text-align: right;
}
</style>
