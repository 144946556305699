<template>
    <div id="createPartner">
        <loader-component v-if="showLoader"></loader-component>
        <div class="create_partner">
            <div class="upperSection">
            <mds-layout-grid class="create_partner">
                <mds-row>
                    <span>
                        <router-link
                            :to="{
                                name: getRouteDetails(
                                    '/data-dissemination/partners'
                                ),
                            }"
                        >
                            Partners
                        </router-link>
                    </span>
                    <span class="create_partner_router">
                        / {{ partnerTitle }}
                    </span>
                </mds-row>
                <mds-row class="create_partner_heading">
                    {{ partnerTitle }}
                </mds-row>
            </mds-layout-grid>
            <mds-layout-grid>
                <mds-row class="create-data-feed-name-section">
                    <mds-col :cols="4" class="create-data-feed-name">
                        <mds-form>
                            <mds-input
                                maxlength="250"
                                label="Partner Name"
                                placeholder="Enter a name"
                                :disabled="isPartnerNameDisable"
                                v-model.trim="partnerName"
                            ></mds-input>
                        </mds-form>
                    </mds-col>
                </mds-row>
            </mds-layout-grid>

            <!-- middle section -->
            <div class="create_partner_middle_section">
                <div class="create_partner_border"></div>
                <div>Delivery Methods</div>
                <div class="create_partner_headerPanel">
                    <header-button-panel
                        :buttonDetails="buttonPanelDetails"
                        :counterDetails="counterDetails"
                        @panelButtonClicked="onClickPanelButton"
                    ></header-button-panel>
                </div>
                <!-- table of delivery methods -->
                <div class="partnerTable">
                    <div class="partner_table">
                        <mds-table
                            multiselection
                            row-hover
                            show-sortable
                            v-if="deliveryMethodLength > 0"
                        >
                            <mds-thead
                                @mds-thead-multiselection-changed="
                                    handleMultiSelection($event)
                                "
                                :indeterminate="selectionStatus.isIndeterminate"
                                :checked="selectionStatus.isAllSelected"
                            >
                                <mds-th
                                    v-for="(
                                        header, index
                                    ) in deliveryMethodHeader"
                                    :key="index"
                                    :sortable="header.sortable"
                                    :sorted="header.sorted"
                                    :right-aligned="header.align === 'right'"
                                    @mds-th-sort-changed="
                                        handleSortEvent(
                                            index,
                                            header.fieldName,
                                            $event
                                        )
                                    "
                                >
                                    {{ header.text }}
                                </mds-th>
                            </mds-thead>
                            <mds-tbody>
                                <mds-tr
                                    v-for="(row, index) in deliveryMethodRow"
                                    :key="index"
                                    :checked="row.checked"
                                    :multiselection-label="row.name"
                                    @mds-tr-multiselection-changed="
                                        handleRowSelectEvent(index, $event, row)
                                    "
                                >
                                    <mds-td
                                        v-for="(
                                            header, i
                                        ) in deliveryMethodHeader"
                                        :key="i"
                                        :right-aligned="
                                            header.align === 'right'
                                        "
                                    >
                                        <template
                                            v-if="
                                                header.fieldName === 'actions'
                                            "
                                        >
                                            <span
                                                ><mds-button
                                                    @click="
                                                        rowDeleteActionButton(
                                                            row
                                                        )
                                                    "
                                                    :disabled="
                                                        rowDeleteDisabledButton
                                                    "
                                                    class="actionButton"
                                                    icon="trash"
                                                    variation="icon-only"
                                                    type="button"
                                                ></mds-button
                                            ></span>
                                        </template>
                                        <template v-else>
                                            {{ row[header.fieldName] }}
                                        </template>
                                    </mds-td>
                                </mds-tr>
                            </mds-tbody>
                        </mds-table>
                        <mds-empty-state
                            v-if="deliveryMethodLength == 0"
                            class="no-results-message"
                            size="medium"
                            title="Configure delivery methods"
                            message=""
                        >
                            <template v-slot:mds-empty-state-actions>
                                <mds-button
                                    size="medium"
                                    variation="secondary"
                                    @click="addDeliveryMethodModal"
                                    >Add delivery method</mds-button
                                >
                            </template>
                        </mds-empty-state>
                    </div>
                </div>
            </div>
            </div>
            <!-- Footer Section -->
            <common-footer
                buttonName="Save"
                @cancelButton="cancelButton"
                @saveButton="saveButton"
                :isDisabled="!saveButtonDisable"
            >
            </common-footer>
        </div>
    </div>
</template>

<script>
import { MdsLayoutGrid, MdsRow, MdsCol } from "@mds/layout-grid";
import MdsForm from "@mds/form";
import MdsInput from "@mds/input";
import CommonFooter from "../components/commonFooter.vue";
import HeaderButtonPanel from "../../../common_components/HeaderButtonPanel.vue";
import { MdsButton } from "@mds/button";
import {
    MdsTable,
    MdsThead,
    MdsTh,
    MdsTr,
    MdsTbody,
    MdsTd,
} from "@mds/data-table-4.0.2";
import MdsEmptyState from "@mds/empty-state";
import LoaderComponent from "../../../ui_component/loaderComponent.vue";

export default {
    name: "createPartners",
    components: {
        MdsLayoutGrid,
        MdsRow,
        MdsCol,
        MdsInput,
        CommonFooter,
        HeaderButtonPanel,
        MdsButton,
        MdsTable,
        MdsThead,
        MdsTh,
        MdsTr,
        MdsTbody,
        MdsTd,
        MdsEmptyState,
        MdsForm,
        LoaderComponent,
    },
    data() {
        return {
            isPartnerNameDisable: false,
            partnerName: "",
            partnerId: "",
        };
    },
    props: {
        partnerTitle: {
            type: String,
            default: "",
        },
        buttonPanelDetails: {
            type: Array,
            default: () => [],
        },
        counterDetails: {
            type: Object,
            default: () => {},
        },
        filterObject: {
            type: Array,
            default: () => [],
        },
        deliveryMethodRow: {
            type: Array,
            default: () => [],
        },
        deliveryMethodHeader: {
            type: Array,
            default: () => [],
        },
        rowDeleteDisabledButton: {
            type: Boolean,
            default: false,
        },
        editPartnerData: {
            type: Object,
            default: () => {},
        },
        sortType: {
            type: Boolean,
            default: false,
        },
        sortOrder: {
            type: Number,
            default: 0,
        },
        sortColIndex: {
            type: Number,
            default: 0,
        },
        showLoader: {
            type: Boolean,
            default: true,
        },
    },
    async mounted() {
        if (Object.keys(this.editPartnerData).length) {
            const { vendorname, vendorid } = this.editPartnerData;
            this.partnerName = vendorname;
            this.partnerId = vendorid;
            this.isPartnerNameDisable = true;
        }
    },
    computed: {
        deliveryMethodLength() {
            if (this.deliveryMethodRow !== undefined) {
                if (this.deliveryMethodRow.length > 0) {
                    return this.deliveryMethodRow.length;
                } else {
                    return 0;
                }
            } else {
                return 0;
            }
        },

        selectionStatus() {
            const filtered = this.deliveryMethodRow.filter(
                (row) => row.checked === true
            );

            return {
                isIndeterminate:
                    filtered.length > 0 &&
                    filtered.length < this.deliveryMethodRow.length,
                isAllSelected:
                    filtered.length === this.deliveryMethodRow.length &&
                    filtered.length != 0 &&
                    this.deliveryMethodRow != 0,
            };
        },

        cssVariables() {
            return {
                "--menuWidth": this.menuwidth,
            };
        },

        saveButtonDisable() {
            return this.partnerName && this.deliveryMethodRow.length;
        },
    },
    methods: {
        getRouteDetails(url) {
            const routeDetails = this.$router.resolve({
                path: url,
            });
            return routeDetails.resolved.name;
        },

        saveButton() {
            if (Object.keys(this.editPartnerData).length) {
                this.$emit("updatePartner");
            } else {
                this.$emit("savePartner", this.partnerName);
            }
        },
        cancelButton() {
            this.$router.push("/data-dissemination/partners");
        },

        onClickPanelButton(event) {
            this.$emit("onClickPanelButton", event);
        },
        addDeliveryMethodModal() {
            this.$emit("onClickPanelButton", "Add Delivery Method");
        },
        rowDeleteActionButton(value) {
            this.$emit("rowDeleteActionButton", value);
        },

        handleMultiSelection(event) {
            this.$emit("handleMultiSelection", event);
        },

        handleRowSelectEvent(index, event) {
            this.$emit("handleRowSelectEvent", index, event);
        },

        //Sorting
        handleSortEvent(colIndex, colName) {
            this.$emit("handleSortEvent", colIndex, colName);
        },
    },
};
</script>

<style lang="scss" scoped>
@import "../../../../assets/css/styles.scss";

.create_partner {
    padding: 8px 16px 0 16px;
}
.create_partner_heading {
    margin-top: $mds-space-2-x;
    margin-bottom: $mds-space-2-x;
    left: 16px;
    top: 50px;
    font-style: normal;
    font-weight: 250;
    font-size: 32px;
    line-height: 36px;
}

.create_partner_router {
    margin-left: $mds-space-half-x;
}
.create_partner_middle_section {
    padding: 16px 0px 0 0px;
    height: 420px;
}
.create_partner_border {
    margin-top: $mds-space-1-x;
    margin-bottom: $mds-space-1-x;
    border-bottom: 2px solid $mds-interactive-color-secondary-active;
}

.create_partner_headerPanel {
    margin-top: $mds-space-1-x;
    width: 100%;
}

.partnerTable {
    margin-top: 13px;
    height: 300px;

    .partner_table {
        height: 100%;
        max-height: 70vh;
        overflow: auto;
    }
}

.actionButton {
    margin-left: 5px;
    margin-right: 5px;
}

.no-results-message {
    margin: auto;
    min-width: 50%;
    margin-top: 16px;
}

.move-cancel-save-button-container {
    margin-right: calc(292px - 55px);
}
.upperSection{
    min-height: 83vh;
}
</style>
